
export const reviews = [
    {
        name: 'places/ChIJBQ6nSq_tGGAR2uYuHUOpzH8/reviews/ChZDSUhNMG9nS0VJQ0FnSURuOWREUmFREAE',
        relativePublishTimeDescription: '5 か月前',
        rating: 5,
        text: {
            text: '落ち着いた雰囲気で清潔感のある接骨院です。とても感じの良い若い先生でした。痛みの原因をわかりやすく説明していただき、自分の身体の動かし方の悪い癖がよくわかりました。施術後はだいぶ痛みが楽になり、自宅でできる運動も教わったので、実践したいと思います。丁寧に診ていただいたので、また身体に痛みが出たら予約します。（LINEで予約をお願いできるのも楽です）',
            languageCode: 'ja'
        },
        authorAttribution: {
            displayName: '中野清美',
            uri: 'https://www.google.com/maps/contrib/105475197722199014254/reviews',
            photoUri: 'https://lh3.googleusercontent.com/a/ACg8ocJUHGXlCHUFiIqjXl8MJLB_bTYQ0cRMRxBJ3SWgiNif1ClK1g=s128-c0x00000000-cc-rp-mo'
        },
        publishTime: '2024-10-08T10:42:20.854634Z',
        flagContentUri: 'https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSURuOWREUmFREAE&d=17924085&t=1',
        googleMapsUri: 'https://www.google.com/maps/reviews/data=!4m6!14m5!1m4!2m3!1sChZDSUhNMG9nS0VJQ0FnSURuOWREUmFREAE!2m1!1s0x6018edaf4aa70e05:0x7fcca9431d2ee6da'
    },
    {
        name: "places/ChIJBQ6nSq_tGGAR2uYuHUOpzH8/reviews/ChdDSUhNMG9nS0VJQ0FnTURRX3UtbzF3RRAB",
        relativePublishTimeDescription: "2 週間前",
        rating: 5,
        text: {
            text: '腰痛でお世話になっています。\nときわ台駅からも近く、通常の接骨院では受けられない施術が受けられる珍しい接骨院です。\n\nキネシオテープの開発者と師弟関係にあるようで、キネシオテープの貼り方が芸術です。\n\n接骨院は指圧のイメージですが、対処ではなく先々までの運動指導まで的確に行っていただけます。\n\n院長の明るい笑い方についつい笑顔が綻ぶ素敵な接骨院なのでおすすめです。\n\nこれからもお世話になります。',
            languageCode: 'ja'
        },
        authorAttribution: {
            displayName: "king Nao",
            uri: "https://www.google.com/maps/contrib/117127347290325467569/reviews",
            photoUri: "https://lh3.googleusercontent.com/a-/ALV-UjWzXc7BE32m97xJHLal8ai0yb6NulM76JfoswnkJUVeF2FkOwlMYQ=s128-c0x00000000-cc-rp-mo"
        },
        publishTime: "2025-03-11T11:48:51.963188Z",
        flagContentUri: "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnTURRX3UtbzF3RRAB&d=17924085&t=1",
        googleMapsUri: "https://www.google.com/maps/reviews/data=!4m6!14m5!1m4!2m3!1sChdDSUhNMG9nS0VJQ0FnTURRX3UtbzF3RRAB!2m1!1s0x6018edaf4aa70e05:0x7fcca9431d2ee6da"
    },
    {
        name: "places/ChIJBQ6nSq_tGGAR2uYuHUOpzH8/reviews/ChdDSUhNMG9nS0VJQ0FnTURnemZTajdRRRAB",
        relativePublishTimeDescription: "1 か月前",
        rating: 5,
        text: {
            text: "長年の肩こりを解消したく、通わせていただいております。\n今まで整体には何度か通院したことがあるのですが、ただただ痛いだけで どうしても足が遠のいていました。\n私にとって初めての接骨院でしたが、先生はとても優しく説明も丁寧ですごく分かりやすいので、整体が続かなかった私でも\nまた行きたいと思える素敵な医院です。\n今は肩周りだけではなく、全体的に診ていただいているので本当にありがたいです。\n\n引き続きお世話になりたいと思っております。",
            languageCode: "ja"
        },
        authorAttribution: {
            displayName: "pgmr pgsk",
            uri: "https://www.google.com/maps/contrib/103514445552410471038/reviews",
            photoUri: "https://lh3.googleusercontent.com/a/ACg8ocIw4rDKYAr9EbY6E3PoOCOUF2kUzQaIScqQFRKlM_2C9ar2NA=s128-c0x00000000-cc-rp-mo"
        },
        publishTime: "2025-02-26T13:47:21.430469Z",
        flagContentUri: "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnTURnemZTajdRRRAB&d=17924085&t=1",
        googleMapsUri: "https://www.google.com/maps/reviews/data=!4m6!14m5!1m4!2m3!1sChdDSUhNMG9nS0VJQ0FnTURnemZTajdRRRAB!2m1!1s0x6018edaf4aa70e05:0x7fcca9431d2ee6da"
    },
    {
        name: "places/ChIJBQ6nSq_tGGAR2uYuHUOpzH8/reviews/ChdDSUhNMG9nS0VJQ0FnSUR2MXN1azJnRRAB",
        relativePublishTimeDescription: "3 か月前",
        rating: 5,
        text: {
            text: "基本的な整体だけではなく、負荷がかかっている筋肉や上手く使えていない箇所など、身体の状態に合わせて施術をしていただきました。\nまた、今後より動きがよくなるように、姿勢改善や筋肉の動かし方なども教えていただき、回数を重なるごとに身体の調子がよくなってきているように感じます。気になる箇所に補助用のテーピングをしてくれるので、普段も意識しつつ改善に向けて取り組むことができています。",
            languageCode: "ja"
        },
        authorAttribution: {
            displayName: "せぶん",
            uri: "https://www.google.com/maps/contrib/110975566359372967102/reviews",
            photoUri: "https://lh3.googleusercontent.com/a/ACg8ocLkcMcvXlTS1AA_pGy7xIutTqYtgIU2HcfJkttJfH_15J0TIQ=s128-c0x00000000-cc-rp-mo"
        },
        publishTime: "2024-12-20T09:24:18.933436Z",
        flagContentUri: "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUR2MXN1azJnRRAB&d=17924085&t=1",
        googleMapsUri: "https://www.google.com/maps/reviews/data=!4m6!14m5!1m4!2m3!1sChdDSUhNMG9nS0VJQ0FnSUR2MXN1azJnRRAB!2m1!1s0x6018edaf4aa70e05:0x7fcca9431d2ee6da"
    },
    {
        name: "places/ChIJBQ6nSq_tGGAR2uYuHUOpzH8/reviews/ChZDSUhNMG9nS0VJQ0FnSUN2X0pDMk53EAE",
        relativePublishTimeDescription: "1 か月前",
        rating: 5,
        text: {
            text: "いつもお世話になってます\n膝の痛みや腫れで歩くのが大変になり、通わせていただいてます\n院長先生の説明もわかりやすく、的確な治療とアドバイスをいただき\nお陰様で、趣味も楽しめるようになりました\nあの痛みはどこへ行った？\n身体が軽くなってます",
            languageCode: "ja"
        },
        authorAttribution: {
            displayName: "MISIA MISIA",
            uri: "https://www.google.com/maps/contrib/116273362734049132054/reviews",
            photoUri: "https://lh3.googleusercontent.com/a/ACg8ocLNgH4xo7wzEN-0RKhYCBuv3a7HOB91it5RuZLOWiN_el-rOA=s128-c0x00000000-cc-rp-mo"
        },
        publishTime: "2025-02-03T01:05:58.871973Z",
        flagContentUri: "https://www.google.com/local/review/rap/report?postId=ChZDSUhNMG9nS0VJQ0FnSUN2X0pDMk53EAE&d=17924085&t=1",
        googleMapsUri: "https://www.google.com/maps/reviews/data=!4m6!14m5!1m4!2m3!1sChZDSUhNMG9nS0VJQ0FnSUN2X0pDMk53EAE!2m1!1s0x6018edaf4aa70e05:0x7fcca9431d2ee6da"
    },
    {
        name: "places/ChIJBQ6nSq_tGGAR2uYuHUOpzH8/reviews/ChdDSUhNMG9nS0VJQ0FnSUR2X3JmUHNRRRAB",
        relativePublishTimeDescription: "3 か月前",
        rating: 5,
        text: {
            text: "室内が広く圧迫感のない新しくて清潔感のある接骨院です。丁寧な施錠はもちろん痛みへの対処法や日常生活で気をつけることなど、わかりやすくアドバイスしていただけます。\n以前から何度かお世話になっていますが、大変だった状態から回復できたこともあり、とても頼りにしています。",
            languageCode: "ja"
        },
        authorAttribution: {
            displayName: "riont",
            uri: "https://www.google.com/maps/contrib/109017171136100734395/reviews",
            photoUri: "https://lh3.googleusercontent.com/a/ACg8ocKfac_eGmxMu9nXBkgPu3iUIzSILyRVBlLtWtwpx9EgNn22daE=s128-c0x00000000-cc-rp-mo"
        },
        publishTime: "2024-12-21T01:10:38.654398Z",
        flagContentUri: "https://www.google.com/local/review/rap/report?postId=ChdDSUhNMG9nS0VJQ0FnSUR2X3JmUHNRRRAB&d=17924085&t=1",
        googleMapsUri: "https://www.google.com/maps/reviews/data=!4m6!14m5!1m4!2m3!1sChdDSUhNMG9nS0VJQ0FnSUR2X3JmUHNRRRAB!2m1!1s0x6018edaf4aa70e05:0x7fcca9431d2ee6da"
    }


];

