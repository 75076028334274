
export const MenuItems = [
    {
        name: 'About',
        link: 'about',
        fName: 'AboutPage',
        name_jp: 'ひかる整骨院について'
    },
    {
        name: 'Treatments',
        link: 'treatments',
        fName: 'TreatmentsPage',
        dropdownMenu: [
            {
                id: 1,
                name: 'キネシオテーピング療法',
                link: 'kinesio'
            },
            {
                id: 2,
                name: '筋スラッキング療法',
                link: 'muscle_slugging'
            },
            {
                id: 3,
                name: "クライオセラピー＆アイスマッサージ",
                rname: ['クライオセラピー＆', 'アイスマッサージ'],
                link: 'cryotherapy'
            },
            {
                id: 4,
                name: "筋膜リリース",
                link: 'myofasical_release'
            },
            {
                id: 5,
                name: '運動療法',
                link: 'exercise_therapy'
            },
            {
                id: 6,
                name: '物理療法',
                link: 'physical_therapy'
            },
        ],
        name_jp: '施術内容'
    },

    {
        name: 'ServiceFlow',
        link: 'serviceflow',
        fName: 'ServiceFlowPage',
        name_jp: '施術の流れ'
    },
    // {
    //     name: 'Reviews',
    //     link: 'reviews',
    //     fName: 'ReviewsPage'
    // },
    {
        name: 'Price',
        link: 'price',
        fName: 'PricePage',
        name_jp: '料金'
    },
    {
        name: 'reviews',
        link: 'reviews',
        fName: 'ReviwsPage',
        name_jp: 'お客様の声'
    },
    {
        name: 'Access',
        link: 'access',
        fName: 'AccessPage',
        name_jp: 'アクセス'
    },
    {
        name: 'News',
        link: 'news',
        fName: 'NewsPage',
        name_jp: 'お知らせ'
    },

]



export const TellPhoneNums = {
    num: '03-6454-9222',
    link: 'tel:03-6454-9222'
}

export const AddressInfo = {
    num: '174-0071',
    jpn: '東京都板橋区常盤台3-20-8',
    eng: '',
    link: ''

}

export const LINEInfo = {
    friendUrl: 'https://lin.ee/B38US7M'
}

export const InstagramInfo = {
    url: 'https://www.instagram.com/hikaru.acu0606?igsh=am4zcXMxdG0zbTBj&utm_source=qr '
}

export const FirstViewInfo = [
    {
        pageName: '',
        imagePath: ''
    },
    {
        pageName: 'treatments',
        imagePath: ''
    },
    {
        pageName: 'about',
        imagePath: ''
    },
    {
        pageName: 'reviews',
        imagePath: ''
    },
    {
        pageName: 'price',
        imagePath: ''
    },
    {
        pageName: 'access',
        imagePath: ''
    }
]

export const ServiceFlowInfo = [
    {
        name: "ご予約(LINEまたはお電話)",
        content: "お電話または、LINE公式アカウントからご自身の予定に合わせてご予約していただきます。",
        image: [
            '1'
        ],
        id: 'reserve'

    },
    {
        name: "問診",
        content: "問診表に症状を記入していただいて、問診をさせて頂きます。”痛み”や”不調”の原因は人によって様々です。問診から身体の痛みや不調の内容を詳しく聞いていきます。また身体の状態を詳しく把握していきたいので生活サイクル、既往歴、手術歴、投薬の有無等細かく聞かせていただきます。",
        image: [
            '1', '2'
        ],
        id: 'interview'
    },
    {
        name: "検査",
        content: "人によって痛みや不調の症状、原因は様々です。また原因が患部だけとも限りません。なぜ症状がでているのか様々な検査をさせて頂きます。",
        image: [
            '1'
        ],
        id: 'inspection'
    },
    {
        name: "治療内容の説明",
        content: "痛みや不調の症状、原因をなぜでているのかを説明します。その後に治療のプランをしっかりと説明させていただきます。",
        image: [
            '1', '2'
        ],
        id: 'explain'
    },
    {
        name: "施術",
        content: "患者様の身体の状態に合わせた施術方法で、”早期回復”を目指した施術を行います。",
        image: [
            '1', '2', '3', '4'
        ],
        id: 'treatment'
    },
    {
        name: "テーピング",
        content: "鎮痛、腫れやムクミの軽減、筋肉のサポート等、患者様の身体の状態に合わせてテーピングを貼付します。",
        image: [
            '1', '2'
        ],
        id: 'terping'
    },
    {
        name: "運動指導",
        content: "患者様の身体の状態に合わせて早期回復、ケガ防止の運動指導を行います。",
        image: [
            '1'
        ],
        id: 'exercise'
    },
    {
        name: "生活指導",
        content: "身体を悪くしてしまう動作や姿勢をとらなければ痛みや不調はでることは無いはずです。問診やお話を聞いていく際に、日常的に行ってしまっている悪い動作、姿勢、癖を改善する指導を行います。",
        image: [
            '1'
        ],
        id: 'posture'
    },
    {
        name: "料金のご案内",
        content: "ここまでの流れで治療をさせていただいて、自費で約6500円ほどの料金になります。保険適用できる場合や症状の状態等に合わせて料金を調整させていただきます。",
        image: [
            '1'
        ],
        id: 'price'
    },
]

export const GreetingContents = {
    name: "中澤 輝",
    title: "ひかる接骨院 院長",
    contents: [
        "　学生の頃から空手、バスケ、ラグビーとスポーツをしてきて数々のケガを経験してきました。骨折だけで10数箇所、脱臼、ヘルニア、捻挫、靭帯損傷等などここに書くとかなり長くなってしまいます笑。ケガは治っても身体の痛みや不調が取れず、色々な治療を受けてきました。",
        "　ですが、なかなか痛みは取れず、身体は思うように動かず、なんで良くならないんだろうと不安と本当に良くなるのだろうかと不安に思う辛い日々を過ごしました。そこで「自分のことは自分で治そう」と思い、治療家の道を目指し現在は元気に仕事ができています。",
        "　この経験から痛いことの辛さ、リハビリの辛さ、本当に良くなるのか？という不安感はとてもよくわかります。辛い思いをしている方々が、早く辛い症状から回復して、痛みのない当たり前の生活を送れるように精一杯頑張らせて頂きたいと思います。",

    ],
    content_not_collaps: "　当院では、なぜ痛いのか、なぜ痛めてしまったのか、なぜ症状が続いているのかと”なぜ”を患者様一人一人しっかりと追求して説明と治療を行っていきます。"
}