import { Box, Text } from "@chakra-ui/react"
import { GreetingContents } from "../../data/Infomations"
import { useState } from "react"
import { motion } from "framer-motion";

export const GreetingHome = () => {
    const Collapsible = (() => {
        const [isOpen, setIsOpen] = useState(false);

        return (
            <>
                <div className="w-80 p-4 border rounded-lg shadow">
                    <button
                        className="w-full text-left font-bold p-2 bg-blue-500 text-white rounded"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        {isOpen ? "▲ 閉じる" : "▼ 開く"}
                    </button>
                    <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: isOpen ? "auto" : 0, opacity: isOpen ? 1 : 0 }}
                        transition={{ duration: 0.5, ease: "easeInOut" }}
                        className="overflow-hidden"
                    >
                        <div className="mt-2 p-2 border rounded bg-gray-100">
                            {GreetingContents.contents.map((content, id) => {
                                return (
                                    <>
                                        <Text key={id}>{content}</Text>
                                    </>

                                )

                            })}
                        </div>
                    </motion.div>
                </div>
            </>
        )
    })
    return (
        <Box
            fontFamily='"Zen Maru Gothic"'
            fontSize={["sm", "md", "md"]}
            fontWeight={["medium"]}
            gap={['0.5em']}
            display={['flex']}
            flexWrap={['wrap']}
        >
            <Box
                width={['100%']}
                backgroundSize={'contain'}
                backgroundRepeat={'no-repeat'}
                backgroundPosition={'center'}
                backgroundImage={`url("${process.env.PUBLIC_URL}/images/director.jpg")`}
                height={['200px', '200px', '200px']}
                margin={['5px 10px', '5px 10px', '25px 10px',]}
                alt={'院長写真'}
            />
            <Collapsible />
            <Text>{GreetingContents.content_not_collaps}</Text>
            <Text w={['100%']} textAlign={['end']}>{GreetingContents.title}</Text>
            <Text w={['100%']} textAlign={['end']}>{GreetingContents.name}</Text>
        </Box>

    )
}
