import FirstView from "../..//components/firstView";
import GoogleReviews from "../../components/reviews/GoogleReviews";
import { Box, VStack } from "@chakra-ui/react";
import { BackgroundWrapper } from "../../components/wrapper/BackgroundWrapper";
import { HeadingLogo } from "../../components/heading/HeadingLogo";
import ReviewSlider from "../../components/reviews/test";

export default function ReviewsPage() {
    const StyledBox = ({ children, ...rest }) => {
        return (
            <Box
                padding={['30px 0 50px']}
                position='relative'
                w={['100%']}
                {...rest}
            >
                {children}
            </Box>
        )
    }
    return (
        <>

            <FirstView imagePath={`${process.env.PUBLIC_URL}/images/slide/1.jpg`} text={'Review'} />
            <StyledBox>
                <HeadingLogo
                    title={'お客様の声'}
                    title_jp={''}
                />
                <VStack>
                    <Box
                        width={['100%']}
                        backgroundSize={'contain'}
                        backgroundRepeat={'no-repeat'}
                        backgroundPosition={'left'}
                        backgroundImage={`url("${process.env.PUBLIC_URL}/images/reviews/1.jpg")`}
                        height={['300px', '300px', '500px']}
                        margin={['5px 10px', '5px 10px', '5px 10px',]}
                        alt={'お客様1'}
                    />
                    <Box
                        width={['100%']}
                        backgroundSize={'contain'}
                        backgroundRepeat={'no-repeat'}
                        backgroundPosition={'right'}
                        backgroundImage={`url("${process.env.PUBLIC_URL}/images/reviews/2.jpg")`}
                        height={['300px', '300px', '500px']}
                        margin={['5px 10px', '5px 10px', '5px 10px',]}
                        alt={'お客様2'}
                    />
                    <Box
                        width={['100%']}
                        backgroundSize={'contain'}
                        backgroundRepeat={'no-repeat'}
                        backgroundPosition={'left'}
                        backgroundImage={`url("${process.env.PUBLIC_URL}/images/reviews/3.jpg")`}
                        height={['300px', '300px', '500px']}
                        margin={['5px 10px', '5px 10px', '5px 10px',]}
                        alt={'お客様3'}
                    />
                </VStack>

            </StyledBox>
            <StyledBox>
                <BackgroundWrapper color="#f5f0e" />

                <HeadingLogo
                    title={'GoogleReviews'}
                    title_jp={'レビュー'}
                />
                <GoogleReviews />

            </StyledBox>

        </>
    );
}
