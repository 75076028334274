import { useState } from "react"
import { Box, Icon, Img, Text, VStack, Button } from "@chakra-ui/react";
import { CiClock2 } from "react-icons/ci";
import { IoStar } from "react-icons/io5";


export const SingReviewCard = ({ review }) => {
    const [isExpand, setIsExpand] = useState(false);
    const toggleExpand = () => setIsExpand(!isExpand)
    const RatingStar = ({ count }) => {
        return (
            <>
                {Array.from({ length: count }).map((_, index) => (
                    <>
                        <Icon key={index} as={IoStar} color={"#FFC600"} fontSize={"22px"} />
                    </>
                ))}
            </>


        )
    }
    const ReviewText = ({ review }) => {
        // 改行コード \n を <br /> タグに変換
        const formattedText = review.text.text.split('\n').map((line, index) => (
            <span key={index}>
                {line}
                <br />
            </span>
        ));

        return <div>{formattedText}</div>;
    };
    return (
        <>
            <VStack
                width={['280px']}
                gap='0'
                height={isExpand ? 'none' : ['250px', '250px', '280px']}
                padding='0.5em'
                overflow='hidden'
                boxShadow='2px 2px 7px 2px #777777'
                borderRadius='7px'
                transition='all 0.4s'
                cursor='pointer'
                backgroundColor='white'
                margin={"10px auto 10px auto"}

            >


                <Box
                    className="newsImage"
                    height='40px'
                    aspectRatio='1/1'
                    overflow='hidden'
                >
                    <Img src={review.authorAttribution.photoUri} />

                </Box>
                <Box
                    fontSize='14px'
                    w='100%'
                    display='flex'
                    justifyContent='space-between'
                >
                    <span><Icon as={CiClock2} verticalAlign='-2.2px' />{review.publishTime.substring(0, 10)}</span>

                </Box>

                <Box

                >
                    <Text><RatingStar count={review.rating} /></Text>
                </Box>

                <VStack
                    className="newsContent"
                    width='100%'
                    height={isExpand ? 'none' : ['120px', '120px', '130px']}
                >
                    <Text
                        fontSize='sm'
                        className="blog-excerpt"
                        overflow={"hidden"}
                    >
                        {ReviewText({ review })}
                    </Text>
                </VStack>
                <Box
                    display={"flex"}
                    textAlign={"center"}
                    justifyContent={"center"}
                >
                    <Button
                        onClick={toggleExpand}
                        fontSize={"12px"}
                        background={"transparent"}
                        _hover={{ boxShadow: '5px 5px 20px -10px #777777', transform: 'translateY(2px)', color: '#969696' }}
                    >
                        - {isExpand ? "閉じる" : "続きを読む"} -
                    </Button>

                </Box>

            </VStack>
        </>
    )
}