import React from "react";
import Slider from "react-slick";
import { Link } from "@chakra-ui/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Button, Container, IconButton } from "@chakra-ui/react";
import { reviews } from "../../data/reviews"
import { SingReviewCard } from "./SigleReviewCard";
import { FaExternalLinkAlt } from "react-icons/fa";


// const reviews = [
//     { id: 1, name: "Taro", text: "素晴らしいサービスでした！" },
//     { id: 2, name: "Hanako", text: "とても満足しています。" },
//     { id: 3, name: "Jiro", text: "また利用したいと思います！" },
//     { id: 4, name: "Yuki", text: "スタッフの対応が良かったです。" },
//     { id: 5, name: "Ken", text: "リピート決定！" },
//     { id: 6, name: "Mika", text: "とても清潔で快適でした。" },
// ];

const GoogleReviews = () => {
    const settings = {
        dots: true,
        infinite: true,
        centerPadding: "0px",
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,

        prevArrow: (
            <IconButton
                h={"10px"}
                aria-label="Previous"
                position="absolute"
                left="-20px"
                top="50%"
                transform="translateY(-50%)"
                zIndex="2"
                borderRadius="full"
                colorScheme='none'
                paddingTop={'5px'}
                color="logoColor.g" // 矢印色を指定
                _hover={{ color: "logoColor.g" }} // ホバー時の色
            />
        ),
        nextArrow: (
            <IconButton
                aria-label="Next"
                position="absolute"
                right="-20px"
                top="50%"
                transform="translateY(-50%)"
                zIndex="2"
                borderRadius="full"
                colorScheme='none'
                color="logoColor.g" // 矢印色を指定
                textColor="logoColor.g"
                paddingTop={'5px'}
                _hover={{ color: "logoColor.g" }} // ホバー時の色
            />
        ),
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
        ],
        appendDots: (dots) => (
            <Box mt={4}>
                <ul style={{ margin: "0px", padding: "0px" }}> {dots} </ul>
            </Box>
        ),
    };

    return (
        <>
            <Container maxW="6xl" py={10} display="flex" justifyContent="center">
                <Box
                    w={"full"}
                    maxW="6xl"
                >
                    <Slider {...settings}>
                        {reviews.map((review) => (
                            <SingReviewCard review={review} />
                        ))}
                    </Slider>

                </Box>
            </Container>
            <Box
                margin={"0px auto 50px auto"}
                textAlign={"center"}
            >
                <Link href="https://g.page/r/CdrmLh1Dqcx_EAE/review" isExternal>
                    <Button

                        colorScheme='green'
                        variant='outline'
                        rightIcon={<FaExternalLinkAlt />
                        }>レビューをを書く</Button>
                </Link>
            </Box>


        </>

    );
};

export default GoogleReviews;
